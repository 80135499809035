import { useEffect, useState } from "react";
import "./App.css";
import Section from "./Section";
import * as amplitude from "@amplitude/analytics-browser";
import Credits from "./Credits";
import { useSearchParams } from "react-router-dom";
import demoData from "./demoData";
const trackedSec: number[] = [];
function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSec, setCurrentSec] = useState(0);
  const [showCredits, setShowCredits] = useState(false);

  useEffect(() => {
    if (trackedSec.includes(currentSec)) return;
    if (process.env.REACT_APP_LOCAL) return;
    if (currentSec === 0) {
      amplitude.init("eede365884b2a5146c81478ba9c682d0");
      const ref = searchParams.get("ref");
      amplitude.track(`ref: ${ref}`);
    }
    trackedSec.push(currentSec);
    amplitude.track(`read section ${currentSec}`);
  }, [currentSec]);

  return (
    <div className="App">
      {demoData.map((secData, idx) => (
        <Section
          {...secData}
          idx={idx}
          key={idx}
          isActive={currentSec === idx}
          isNext={idx === currentSec + 1}
          showNext={currentSec < demoData.length - 1}
          showPrev={true}
          onClickNext={() => {
            if (currentSec < demoData.length - 1) setCurrentSec(currentSec + 1);
          }}
          onClickPrev={() => {
            if (currentSec > 0) setCurrentSec(currentSec - 1);
          }}
        />
      ))}
      <button
        className="btn btn-credit"
        onClick={() => {
          setShowCredits(true);
        }}
      >
        Credits
      </button>
      {showCredits && (
        <div className="modal-container">
          <div
            className="modal-overlay"
            onClick={() => {
              setShowCredits(false);
            }}
          ></div>
          <div className="modal">
            <Credits />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
