import { useEffect } from "react";

{
  /* <div data-paperform-id="vqp8s8wf"></div><script>(function() {var script = document.createElement('script'); script.src = "https://paperform.co/__embed.min.js"; document.body.appendChild(script); })()</script> */
}
const Survey = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://paperform.co/__embed.min.js";
    script.async = true;
    //   script.onload = () => scriptLoaded();

    document.body.appendChild(script);
  }, []);

  return (
    <div
      className="survey-inner"
      prefill-inherit=""
      data-paperform-id="lhwfgajt"
      data-spinner={true}
      data-onpagechange="onPageChange"
      style={{ minHeight: "fit-content" }}
    ></div>
  );
};

export default Survey;
