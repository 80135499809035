import { useEffect, useRef, useState } from "react";
import Video from "./Video";

const transDuration = 500;

const Section = (props: ISection) => {
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.isNext) {
      setLoad(true);
      setShow(false);
    } else if (props.isActive) {
      if (props.onActive) props.onActive();
      setLoad(true);
      setTimeout(() => {
        setShow(true);
      }, 10);
    } else {
      setShow(false);
      setTimeout(() => {
        setLoad(false);
      }, transDuration);
    }
  }, [props]);

  useEffect(() => {
    if (!vidRef.current) return;
    if (show && load) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
  }, [show, load]);

  const vidRef = useRef<IVideoPlayer>(null);

  if (!load) return <></>;
  return (
    <section
      //   ref={props.ref}
      className={`test ${show ? "show" : ""} ${
        props.className ? props.className : ""
      }`}
      key={props.idx}
    >
      {props.other}
      {props.A && (
        <div
          className={`${props.A.className ? props.A.className : "secA"} ${
            props.A.mobileOnly ? "mobile" : ""
          }${props.A?.desktopOnly ? "desktopOnly" : ""}`}
        >
          {props.A.jsx}
          {props.A.image && <img src={props.A.image} alt="" />}
          {props.A.video && (
            <Video
              ref={vidRef}
              src={props.A.video.src}
              muted={true}
              loop={props.A.video.loop !== false}
              controls={false}
              aspect={1}
            />
          )}
        </div>
      )}
      {props.B && (
        <div
          className={`${props.B.className ? props.B.className : "secB"} ${
            props.B.mobileOnly ? "mobile" : ""
          }${props.B.desktopOnly ? "desktop" : ""}`}
        >
          <div className="desktop"></div>
          {props.B.jsx}
          {(props.B.title || props.B.text) && (
            <div>
              {props.B.title && (
                <h2 dangerouslySetInnerHTML={{ __html: props.B.title }}></h2>
              )}
              {props.B.text && (
                <p dangerouslySetInnerHTML={{ __html: props.B.text }}></p>
              )}
            </div>
          )}

          {props.hideNav !== true && (
            <div className="nav-btns">
              {props.showPrev && (
                <button
                  className="btn-prev"
                  onClick={props.onClickPrev}
                  //   onClick={() => {
                  //     if (currentSec > 0) setCurrentSec(currentSec - 1);
                  //   }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                  </svg>
                </button>
              )}
              {props.showNext && (
                <button
                  className="btn-next"
                  onClick={props.onClickNext}
                  // onClick={() => {
                  //   setCurrentSec(currentSec + 1);
                  // }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                  </svg>
                </button>
              )}
            </div>
          )}

          <footer></footer>
        </div>
      )}
    </section>
  );
};

export default Section;
