import Survey from "./Survey";
import * as amplitude from "@amplitude/analytics-browser";

const demoData_default: ISection[] = [
  {
    className: "intro",
    A: {
      // desktopOnly: true,
      video: { src: "./video/low/All_DemoIntro_1.mp4" },
    },
    B: {
      jsx: (
        <>
          <div className="logos">
            <img
              src="../img/logotype.svg"
              alt=""
              style={{ width: "50%", margin: "6px 0" }}
            />
            <img src="../img/tribe.svg" alt="" />
          </div>
          <h3 style={{ fontSize: "1.5rem" }}>
            Metaverse for creator and their community. A 3D Discord & more
          </h3>
          <p style={{ opacity: 0.6 }}>
            This is a demo and feedback survey for Protoworld's <b>TRIBE</b>.{" "}
            <br /> To apply for early access to our beta, please go through the
            demo and provide your feedback.
          </p>
        </>
      ),
      title: "",
      text: ``,
    },
  },

  {
    A: { video: { src: "./video/low/01_lobbyS_1.mp4" } },
    B: {
      title: "A Next-gen Social Metaverse",
      text: "Free metaverse. Homebase for creators and their fans.",
    },
  },
  {
    A: { video: { src: "./video/low/02_bubbleFadein_1.mp4", loop: false } },
    B: {
      title: "The Lobby: A Place for Gathering",
      text: "Hangout and share news feed with frens, in an RPG style",
    },
  },
  {
    A: {
      video: { src: "./video/low/03_rpg_1.mp4" },
    },
    B: {
      title: "Feed in RPG Style",
      text: "Read TRIBE feed and get socialized with fellow members like an RPG.",
    },
  },
  {
    A: {
      video: { src: "./video/low/06_buildTogether_1.mp4" },
    },
    B: {
      title: "Build Together",
      text: "Collaborate with frens, build gallery, social space and live stage together.",
    },
  },
  {
    A: {
      video: { src: "./video/low/09_styles_1.mp4" },
    },
    B: {
      title: "Custom Tribe Style",
      text: "Customizable environment, visual style, avatar, animoji to match your community. It's your TRIBE, your vibe.",
    },
  },
  {
    A: {
      video: { src: "./video/low/05_VIP_1.mp4" },
    },
    B: {
      title: "Creator Economy & <br>Membership",
      text: "Support the TRIBE you love, become a member and access VIP spaces, events, costumes, and full body avatar made by the creator.",
    },
  },
  {
    A: {
      video: { src: "./video/low/08_live_1.mp4" },
    },
    B: {
      title: "Virtual Live Shows, Reimagined",
      text: "Make the audience represented as avatars, interact with you and each other during the live event/stream.",
    },
  },
  {
    A: {
      video: { src: "./video/low/07_lobbyLarge_1.mp4" },
    },
    B: {
      title: "Community City",
      text: "Grow with your community and expand the TRIBE into a city with massive and multiple spaces.",
    },
  },
  {
    hideNav: true,
    className: "sec-survey",
    onActive: () => {
      amplitude.track(`load survey`);
    },
    A: {
      desktopOnly: true,
      video: { src: "./video/low/09_styles_1.mp4" },
    },
    B: {
      className: "survey-container",
      jsx: <Survey />,
    },
  },
];

export default demoData_default;
